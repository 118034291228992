/* eslint-disable react-hooks/exhaustive-deps */
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import './styles/App.module.scss';
import Register from './pages/register';
import ForgotPassword from './pages/forgot-password';
import ResetPassword from './pages/resetPassword';
import FullScreenLoader from './components/loader';
import { useEffect, useState } from 'react';
import { getProfile, logout } from 'services/api/users';
import {
  flattenCategories,
  isConnected,
  isLocalhost,
  setSelectables,
} from 'utils/utils';
import Login from 'pages/login';
import Verify from 'pages/verify';
import Auth from 'pages/auth';
import Dashboard from 'pages/dashboard';
import ProfileContext from 'services/profileContext';
import DomainContext from 'services/domainContext';
import { getAllDomains, getCategories, getDetails } from 'services/api/domains';
import Settings from 'pages/settings';
import posthog from 'posthog-js';
import PageWrapper from 'components/pageWrapper';
import ImpersonateWarningBanner from 'components/impersonateWarningBanner';
import CategoryContext from 'services/categoryContext';
import ReactGA from 'react-ga4';
import { GA_MEASUREMENT_ID } from 'utils/constants';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-NPF2FD6V'
}

function App() {
  const [loading, setLoading] = useState(false);
  const getUserProfile = () => {
    if (loading) return;
    setLoading(true);
    return getProfile()
      .then(({ data }) => {
        if (!isLocalhost() && !data.impersonatedBy) {
          if (!profile?.profile) {
            posthog.init('phc_oZTNqgzO6PqKH8fI9y86SnXDhEHpGI5J9lqcqv0aVVk', {
              api_host: 'https://eu.posthog.com',
            });
          }
          posthog.identify(data._id, {
            name: data.name,
            email: data.email,
          });
        }
        setProfile({
          profile: data,
          updateProfile: getUserProfile,
          clearProfile: resetProfile,
        });
        listCategories();

        return listDomains();
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          logout(() => {
            window.location.replace(`${window.location.origin}/#/login`);
            resetProfile();
          });
        } else {
          console.error(err);
        }
      })
      .finally(() => {
        setLoading(false);
        setFirstLoadDone(true);
      });
  };

  const resetProfile = () => setProfile(null);

  const listDomains = () =>
    getAllDomains()
      .then((res) => {
        setDomains({ domains: res.data, updateDomains: listDomains });
        return Promise.all(
          res.data.map((r) => getDetails({ name: r.name }))
        ).then((data) => {
          const enrichedDomains = res.data.reduce((acc, element) => {
            const fullData = data.find((d) => d.data.name === element.name);
            return [
              ...acc,
              {
                ...element,
                ...fullData?.data,
                nbSentBacklinksPending: fullData.data.backlinks?.sent?.filter(
                  (e) => e.status === 'PENDING'
                ).length,
              },
            ];
          }, []);
          setDomains({
            domains: enrichedDomains,
            updateDomains: listDomains,
          });
        });
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          logout(() => {
            window.location.replace(`${window.location.origin}/#/login`);
          });
          resetProfile();
        } else {
          console.error(err);
        }
      })
      .finally(() => setLoading(false));

  const [profile, setProfile] = useState({
    profile: null,
    updateProfile: getUserProfile,
    clearProfile: resetProfile,
  });

  const [domains, setDomains] = useState({
    domains: null,
    updateDomains: listDomains,
  });

  const listCategories = () => {
    if (loadingCategories) return;
    setLoadingCategories(true);
    return getCategories()
      .then(({ data }) => {
        const selectables = setSelectables(data);
        setCategories({
          categories: selectables,
          categoriesFlat: flattenCategories(data).flat(),
          updateCategories: listCategories,
        });
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoadingCategories(false);
      });
  };
  const [categories, setCategories] = useState({
    categories: null,
    categoriesFlat: null,
    updateCategories: listCategories,
  });
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [firstLoadDone, setFirstLoadDone] = useState(false);

  useEffect(() => {
    if (!isLocalhost() && !firstLoadDone) {
      ReactGA.initialize(GA_MEASUREMENT_ID);
      TagManager.initialize(tagManagerArgs)
    }
    if (isConnected()) {
      getUserProfile();
    } else {
      setFirstLoadDone(true);
    }
  }, []);

  if (!firstLoadDone) {
    return <></>;
  }

  const HOLIDAY_CLOSURE = false;

  const closure = (
    <div>
      <PageWrapper width={690}>
        <div style={{ textAlign: 'center' }}>
          <div>
            <img
              src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Activities/Party%20Popper.png"
              alt="Party Popper"
              width="165"
              height="165"
            />
          </div>
          <h1 style={{ color: 'white' }}>
            KarmaLinks.io is currently revamping for an amazing v2, we're coming
            back soon 😇
          </h1>
          <div>
            We're revamping the dashboard and taking your feedback into account{' '}
            <br />
            Stay tuned for the v2!
          </div>
        </div>
      </PageWrapper>
    </div>
  );

  const hasOneValidatedDomain =
    domains?.domains?.filter((a) => a.status === 'APPROVED')?.length > 0;

  return (
    <div className="App">
      {profile &&
        profile.profile &&
        profile.profile.impersonatedBy &&
        profile.profile.impersonatedBy.length > 0 && (
          <ImpersonateWarningBanner profile={profile.profile} />
        )}
      <CategoryContext.Provider value={categories}>
        <DomainContext.Provider value={domains}>
          <ProfileContext.Provider value={profile}>
            <HashRouter>
              {firstLoadDone && (
                <Routes>
                  <Route path="/register" element={<Register />} />
                  <Route
                    path="/login"
                    element={<Login getProfileInfo={getUserProfile} />}
                  />
                  <Route
                    path="/forgot-password"
                    exact
                    element={<ForgotPassword />}
                  />
                  <Route
                    path="/reset-password/:resetPasswordToken"
                    element={<ResetPassword />}
                  />
                  <Route path="/verify/:email" element={<Verify />} />
                  <Route
                    path="/auth/:sessionId"
                    element={<Auth getProfileInfo={getUserProfile} />}
                  />
                  {isConnected() && profile ? (
                    <>
                      <Route
                        path="/dashboard"
                        element={
                          HOLIDAY_CLOSURE && hasOneValidatedDomain ? (
                            closure
                          ) : (
                            <Dashboard />
                          )
                        }
                      />
                      <Route path="/settings" element={<Settings />} />
                    </>
                  ) : (
                    <Route
                      path="/"
                      element={<Navigate to="/login" replace />}
                    />
                  )}

                  <Route path="*" element={<Navigate to="/login" replace />} />
                </Routes>
              )}
            </HashRouter>
          </ProfileContext.Provider>
        </DomainContext.Provider>
      </CategoryContext.Provider>

      {loading && <FullScreenLoader />}
    </div>
  );
}

export default App;
