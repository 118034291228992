import styles from 'styles/components/historyMobile.module.scss';

const HistoryMobile = ({ requests, columns }) => (
  <div className={styles.wrapper}>
    <div className={styles.list}>
      {requests.map((r) => (
        <div className={styles.element}>
          {columns.map((c) => {
            const d = r[c.dataIndex];
            return (
              <div className={styles.datapoint}>
                <div className={styles.columnTitle}>{c.title}</div>
                <div>{c.render(d, r)}</div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  </div>
);

export default HistoryMobile;
