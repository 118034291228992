import styles from 'styles/components/domainRating.module.scss';

const getColorclass = (value) => {
    if(value>80){
        return styles.value80;
    } else  if(value>60){
        return styles.value60;
    } else  if(value>40){
        return styles.value40;
    } else  if(value>20){
        return styles.value20;
    } else  if(value>10){
        return styles.value10;
    } else  if(value>5){
        return styles.value5;
    } else {
        return styles.value0
    }
}

const DomainRating = ({ value = 0 }) => (
  <span className={`${styles.value} ${getColorclass(value)}`}>{value}</span>
);

export default DomainRating;
