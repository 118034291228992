import styles from 'styles/components/giveBacklinkModal.module.scss';
import { getCountLabel } from 'utils/utils';
import MetricsVignette from './metricsVignettes';
import { Tag } from 'antd';

const OpportunityDetails = ({ opportunity, categories }) => {
  const mainCategory = (categories || []).find(
    (c) => c._id === opportunity?.categories[0]
  );

  return (
    <div>
      {mainCategory && <Tag color="blue">{mainCategory?.name}</Tag>}
      <div className={styles.vignetteDesc}>{opportunity.description}</div>
      <div className={styles.metrics}>
        <div className={styles.vignetteMetrics}>
          <MetricsVignette
            value={opportunity.metrics.authority.dr}
            label="Domain Rating"
          />
          <MetricsVignette
            value={getCountLabel(opportunity.metrics.traffic.organic)}
            label="Organic Traffic"
          />
        </div>
      </div>
    </div>
  );
};

export default OpportunityDetails;
