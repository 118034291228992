import { Tag, Tooltip } from 'antd';
import styles from 'styles/components/domainMetrics.module.scss';
import MetricsVignette from './metricsVignettes';
import { getCountLabel } from 'utils/utils';
import { GlobalOutlined, LikeFilled } from '@ant-design/icons';
import flag from 'country-code-emoji';
import DomainName from './domainName';
import DomainRating from './domainRating';
import FastResponderBadge from './fastResponderBadge';

const DomainMetrics = ({
  domain,
  categories,
  isGoodMatch = false,
  canSeeYCCompanies = false,
}) => (
  <div className={styles.wrapper}>
    <div className={styles.vignetteCateg}>
      <Tooltip
        title={
          categories.length > 1 ? (
            <div>
              <b>All categories:</b>
              <ul>
                {categories.map((c) => (
                  <li key={c.name}>{c.name}</li>
                ))}
              </ul>
            </div>
          ) : null
        }
      >
        <Tag
          color="blue"
          style={{
            textWrap: 'wrap',
            color: 'white',
            backgroundImage:
              ' linear-gradient(270deg, rgb(68 13 168) 10.33%, rgb(80 55 185) 34.36%, rgb(133 81 172) 60.15%)',
            borderColor: '#3a3043',
          }}
        >
          {categories[0]?.name}
          {categories.length > 1 && (
            <span
              style={{
                marginLeft: 8,
                background: '#2a0a80b5',
                padding: '3px 5px',
                borderRadius: 4,
              }}
            >
              +{categories.length - 1}
            </span>
          )}
        </Tag>
      </Tooltip>
    </div>
    <div className={`${styles.name}`}>
      <Tooltip
        title={
          domain.name
            ? null
            : "You can't see the domain name at the moment. It'll be visible once you'll have an exchange with this domain."
        }
      >
        <div>
          <GlobalOutlined />
          &nbsp; <DomainName domain={domain} />
        </div>
      </Tooltip>
      {canSeeYCCompanies && domain.isYC && (
        <Tooltip title="This is a YC company. You can see that because you are a YC company yourself.">
          <img src="/img/yc.png" className={styles.ycLogo} alt="yc" />
        </Tooltip>
      )}
    </div>

    <div className={styles.vignetteDesc}>{domain.description}</div>
    <div className={styles.tags}>
      {isGoodMatch && (
        <div className={styles.goodMatchWrapper}>
          <Tooltip
            title={
              <div>
                This domain is a good match for an exchange because it has at
                least one category in common and has a DR close to yours.
              </div>
            }
          >
            <Tag className={styles.goodMatch}>
              <LikeFilled style={{ marginRight: 4, fontSize: 12 }} />
              GOOD MATCH
            </Tag>
          </Tooltip>
        </div>
      )}
      {domain.isFastResponder && (
        <div className={styles.fastResponderWrapper}>
          <Tooltip title={<div>This domain is a fast responder!</div>}>
            <FastResponderBadge />
            &nbsp;
          </Tooltip>
        </div>
      )}
    </div>

    <div className={styles.vignetteMetrics}>
      <MetricsVignette
        value={<DomainRating value={domain.metrics.authority.dr} />}
        label="Domain Rating"
      />
      <MetricsVignette
        value={getCountLabel(domain.metrics.traffic.organic)}
        label="Organic Traffic"
      />
      {domain.metrics.traffic?.topCountries &&
        domain.metrics.traffic?.topCountries.length > 0 && (
          <MetricsVignette
            value={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {flag(domain.metrics.traffic?.topCountries[0]?.country)}
                <span
                  style={{ fontSize: 12, marginLeft: 6, fontWeight: 'normal' }}
                >{`(${domain.metrics.traffic.topCountries[0].share.toFixed(
                  0
                )}%)`}</span>
              </div>
            }
            label="Top Country"
            tooltipContent={
              <div>
                Traffic comes from these countries:
                <ol>
                  {domain.metrics.traffic.topCountries.map((c) => (
                    <li key={c.country}>{`${flag(c.country)} - ${
                      c.country
                    } (${c.share.toFixed(0)}%)`}</li>
                  ))}
                </ol>
              </div>
            }
            large
          />
        )}
    </div>
  </div>
);

export default DomainMetrics;
