import { HomeOutlined, MailTwoTone } from '@ant-design/icons';
import { Breadcrumb, Button, Divider, Modal } from 'antd';
import Header from 'components/header';
import PageWrapper from 'components/pageWrapper';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from 'services/api/users';
import DomainContext from 'services/domainContext';
import ProfileContext from 'services/profileContext';
import styles from 'styles/settings.module.scss';

const Settings = () => {
  const { profile, clearProfile } = useContext(ProfileContext);
  const { domains } = useContext(DomainContext);
  const navigate = useNavigate();

  const resetPasswordFunction = () => {
    //logout and redirect to forget pwd
    logout(() => {
      navigate('/forgot-password');
      clearProfile();
    });
  };
  return (
    <>
      <Header />
      <div className={styles.wrapper}>
        <PageWrapper width={900}>
          <Breadcrumb
            items={[
              {
                title: (
                  <a href="/#/dashboard">
                    <HomeOutlined />
                    &nbsp;Dashboard
                  </a>
                ),
              },
              {
                title: 'Settings',
              },
            ]}
          />
          <h1>Settings</h1>
          <div className={styles.accountInfo}>
            <div className={styles.accountInfoElement}>
              <div className={styles.label}>
                <MailTwoTone twoToneColor={'#08a87f'} />
                &nbsp;Email
              </div>
              <div>{profile.email}</div>
              <br />
              <br />
              <div>
                <Button onClick={resetPasswordFunction} type={'primary'} ghost>
                  Update password
                </Button>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div>
            <h2>List of domains</h2>
            <div className={styles.domainList}>
              {domains.map((d, i) => (
                <>
                  <div className={styles.domainWrapper} key={d._id}>
                    <div className={styles.domainName}>{d.name}</div>
                    <div className={styles.domainAction}>
                      <Button
                        type="primary"
                        danger
                        ghost
                        onClick={() => {
                          Modal.error({
                            content:
                              'Please contact the support to remove a domain.',
                          });
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                  {i < domains.length - 1 && <Divider />}
                </>
              ))}
            </div>
          </div>
        </PageWrapper>
      </div>
    </>
  );
};

export default Settings;
