/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  CheckCircleFilled,
  CloseCircleOutlined,
  HourglassOutlined,
  HourglassTwoTone,
  LoadingOutlined,
  // MonitorOutlined,
  PauseCircleOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  SettingOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Badge,
  Button,
  Divider,
  message,
  Modal,
  Tabs,
  Tag,
  Tooltip,
  Typography,
  // Typography,
} from 'antd';
import AnimatedNumber from 'components/animatedNumber';
import BacklinksHistory from 'components/backlinksHistory';
// import BacklinksMonitoring from 'components/backlinksMonitoring';
import BrowseBacklinksOpportunities from 'components/browseBacklinksOpportunities';
import DomainRating from 'components/domainRating';
import DomainSettingsModal from 'components/domainSettingsModal';
import FastResponderBadge from 'components/fastResponderBadge';
import Header from 'components/header';
import KarmaIcon from 'components/karmaIcon';
import Onboarding from 'components/onboarding/index.js';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { deleteRejectedBacklink, getDetails } from 'services/api/domains';
import DomainContext from 'services/domainContext';
import ProfileContext from 'services/profileContext';
import styles from 'styles/dashboard.module.scss';
import { LOCAL_STORAGE } from 'utils/constants';

const Vignette = ({
  name,
  figure,
  featured = false,
  animate = false,
  extra,
}) => (
  <div
    className={`${styles.vignetteWrapper}${
      featured ? ` ${styles.vignetteFeatured}` : ''
    }`}
  >
    {extra && <div className={styles.extra}>{extra}</div>}
    <div className={styles.vignetteFigure}>
      {animate ? <AnimatedNumber value={figure} /> : figure}
    </div>
    <div className={styles.vignetteName}>{name}</div>
  </div>
);

const Dashboard = () => {
  const [forceOnboarding, setForceOnboarding] = useState(false);
  const [isInfoResponsivityOpen, setIsInfoResponsivityOpen] = useState(false);

  const [newDomainFlow, setNewDomainFlow] = useState(false);
  const { profile } = useContext(ProfileContext);
  const { domains, updateDomains } = useContext(DomainContext);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [domainDetailsLoading, setDomainDetailsLoading] = useState(false);
  const [domainDetails, setDomainDetails] = useState(null);
  const [settingsModalisOpen, setSettingsModalisOpen] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [tabQuery, setTabQuery] = useState('browse');
  const [searchParams, setSearchParams] = useSearchParams();

  const getDomainDetails = () => {
    setDomainDetailsLoading(true);
    getDetails({ name: selectedDomain.name })
      .then(({ data }) => {
        setDomainDetails(data);
        const currentDomainNotSetup =
          data?.status === 'TO_REVIEW' ||
          data?.status === 'TO_RE_REVIEW' ||
          !data.verified;

        const isNotTheFirstDomain = domains?.length > 1;

        if (currentDomainNotSetup && isNotTheFirstDomain) {
          localStorage.setItem(
            LOCAL_STORAGE.CURRENT_DOMAIN_VALIDATION,
            data.name
          );
          setNewDomainFlow(true);
        } else if (currentDomainNotSetup) {
          localStorage.setItem(
            LOCAL_STORAGE.CURRENT_DOMAIN_VALIDATION,
            data.name
          );
          // setForceOnboarding(true);
        }
      })
      .catch((e) => {
        console.error(e);
        message.error('Error while loading domain details');
      })
      .finally(() => {
        setDomainDetailsLoading(false);
      });
  };

  const openNewDomainFlow = () => {
    setNewDomainFlow(false);
    localStorage.removeItem(LOCAL_STORAGE.CURRENT_DOMAIN_VALIDATION);
    setTimeout(() => {
      //reset localstorage
      setNewDomainFlow(true);
    });
  };

  useEffect(() => {
    const profileNotValidated =
      !profile.emailVerified || !profile.linkedinProfile;

    const noDomainsVerified =
      domains?.filter(
        (d) => d.status !== 'TO_REVIEW' && d.status !== 'TO_RE_REVIEW'
      )?.length === 0;

    const noDomain = !domains || domains.length === 0 || noDomainsVerified;

    setTimeout(() => {
      setForceOnboarding(profileNotValidated || noDomain);
    }, 500);
  }, [domains]);

  useEffect(() => {
    const domainQuery = searchParams.get('domain');
    const domainQuerySelect =
      domainQuery && domainQuery.length > 0
        ? domains.find((d) => d.name === domainQuery)
        : null;
    const firstDomain = domains && domains.length > 0 ? domains[0] : null;
    setSelectedDomain(domainQuerySelect || firstDomain);
    const tabQueryParam = searchParams.get('tab');
    if (tabQueryParam && tabQueryParam.length > 0) {
      if (tabQueryParam === 'received') {
        setTabQuery('history');
      } else {
        setTabQuery(tabQueryParam);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (selectedDomain && selectedDomain.name) {
      getDomainDetails();
    }
  }, [selectedDomain]);

  useEffect(() => {
    const isInfoResponse = localStorage.getItem(
      LOCAL_STORAGE.INFO_RESPONSIVITY
    );
    if (isInfoResponse && isInfoResponse.length > 0) {
      setIsInfoResponsivityOpen(isInfoResponse === 'true');
    } else {
      setIsInfoResponsivityOpen(true);
    }
  }, []);

  const openSettingsModal = () => setSettingsModalisOpen(true);
  const closeSettingsModal = () => setSettingsModalisOpen(false);

  const getDomainStatusIcon = (domain, status) => {
    if (!domain.live) {
      return <PauseCircleOutlined style={{ marginRight: 4 }} />;
    }
    switch (status) {
      default:
      case 'TO_RE_REVIEW':
      case 'TO_REVIEW':
        return (
          <HourglassOutlined
            twoToneColor="#051200"
            className={styles.iconStatus}
          />
        );
      case 'APPROVED':
        return <CheckCircleFilled className={styles.iconStatus} />;
      case 'REJECTED':
        return <CloseCircleOutlined className={styles.iconStatus} />;
    }
  };

  const removeDomain = () => {
    setRemoveLoading(true);
    deleteRejectedBacklink({ name: domainDetails.name })
      .then(() => {
        //list domains
        //select first one
        return updateDomains().then(() => {
          setSelectedDomain(domains.length > 0 ? domains[0] : null);
        });
      })
      .catch((e) => {
        console.error(e);
        message.error('Error while deleting domain, please try again');
      })
      .finally(() => {
        setRemoveLoading(false);
      });
  };

  const onChangeTab = (activekey) => {
    setSearchParams((prev) => {
      prev.set('tab', activekey);
      return prev;
    });
  };

  const getRejectionText = () => {
    switch (domainDetails.rejectionDetails.reason) {
      default:
      case 'DOMAIN_ALREADY_EXISTS':
        return 'This domain has already been registered on KarmaLinks';
      case 'LOWER_THAN_MIN_DR':
        return 'Your domain rating is too low to enter KarmaLinks for now';
      case 'FORBIDDEN_CATEGORY':
        return 'This category is not authorized on KarmaLinks';
      case 'OTHER':
        return 'This domain was not approved by KarmaLinks';
    }
  };
  const closeInfoResposivity = () => {
    setIsInfoResponsivityOpen(false);
    localStorage.setItem(LOCAL_STORAGE.INFO_RESPONSIVITY, false);
  };

  // const hasAtLeastOneDomainLive =
  //   domains?.filter((d) => d.live && d.status === 'APPROVED').length > 0;

  const pendingRequests = domainDetails?.requests?.filter(
    (e) =>
      (e.status === 'PENDING' && e.initiatedBy !== domainDetails._id) ||
      (e.status === 'ACCEPTED' && e.from === domainDetails._id)
  );

  return (
    <div>
      <Header />
      <>
        <div className={styles.wrapper}>
          <div className={styles.inner}>
            <div className={styles.domainsTitle}>
              {`Domains${
                domains && domains.length > 0 ? ` (${domains.length})` : ''
              }`}
            </div>
            <div className={styles.domainsListWrapper}>
              {domains?.map((d) => {
                const numberRequestPending = d?.requests?.filter(
                  (e) =>
                    (e.status === 'PENDING' && e.initiatedBy !== d._id) ||
                    (e.status === 'ACCEPTED' && e.from === d._id)
                ).length;

                return (
                  <Badge
                    count={numberRequestPending}
                    key={d._id}
                    offset={[-18, 3]}
                  >
                    <Tag
                      onClick={() => {
                        setSearchParams((prev) => {
                          prev.set('domain', d.name);
                          prev.delete('onlyYC');
                          prev.delete('categories');
                          prev.delete('onlyGoodMatch');
                          prev.delete('onlyAvailable');
                          return prev;
                        });
                      }}
                      color={
                        d.status === 'TO_REVIEW' || d.status === 'TO_RE_REVIEW'
                          ? ''
                          : d.status === 'REJECTED'
                          ? 'red'
                          : 'green'
                      }
                      className={`${styles.domainTag} ${
                        selectedDomain?._id === d._id ? styles.selectedTag : ''
                      }${
                        d.status === 'TO_REVIEW' || d.status === 'TO_RE_REVIEW'
                          ? ` ${styles.domainNotLive}`
                          : ''
                      }${
                        d.status === 'REJECTED'
                          ? ` ${styles.domainRejected}`
                          : ''
                      }`}
                      key={d._id}
                    >
                      {selectedDomain?._id === d._id && domainDetailsLoading ? (
                        <LoadingOutlined spin style={{ marginRight: 4 }} />
                      ) : (
                        getDomainStatusIcon(d, d.status)
                      )}
                      {d.name}
                      {d.isYC && (
                        <img
                          src="/img/yc.png"
                          alt="ycombinator"
                          className={styles.ycLogoDomainName}
                        />
                      )}
                      {d.status === 'APPROVED' && d.verified && (
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Divider
                            type="vertical"
                            style={{
                              borderColor: '#00000024',
                              height: '1.3em',
                              marginLeft: 0,
                              marginRight: '6px',
                            }}
                          />
                          {d.points}&nbsp;
                          <KarmaIcon />
                        </span>
                      )}
                    </Tag>
                  </Badge>
                );
              })}
              <Tag
                color="blue"
                className={`${styles.domainTag} ${styles.domainTagAdd}`}
                key={'add'}
                onClick={openNewDomainFlow}
              >
                <PlusCircleOutlined />
                &nbsp;Add a domain
              </Tag>
            </div>
            {domainDetails && (
              <div className={styles.detailsWrapper}>
                {domainDetails.dr < 5 ? (
                  <div>
                    <Alert
                      message="Domain Rating too low"
                      description={
                        <div>
                          Your domain rating is too low to participate (lower
                          than 5).
                          <br />
                          <br />
                          The good news is that we have a solution for you!
                          <br />
                          <br />
                          <b>
                            Use Uneed Directories to automatically grow your DR
                            above 5 and start using KarmaLinks
                          </b>
                          <div>
                            <a
                              href="https://uneed-directories.com/?ref=karmalinks"
                              target={'_blank'}
                              rel="noreferrer"
                            >
                              <Button
                                size="large"
                                type="primary"
                                style={{ marginTop: 14 }}
                              >
                                Go to Uneed Directories
                              </Button>
                            </a>
                          </div>
                        </div>
                      }
                      type="warning"
                      showIcon
                    />
                  </div>
                ) : (
                  <>
                    {!domainDetails.live && (
                      <Alert
                        message="Domain is paused"
                        description={
                          <div>
                            Update domain's settings to set domain to Live again
                            and start collecting backlinks.
                          </div>
                        }
                        type="warning"
                        icon={<PauseCircleOutlined />}
                        showIcon
                        style={{ border: 'none', marginBottom: 12 }}
                      />
                    )}
                    {domainDetails.language !== 'en' &&
                      domainDetails.status !== 'REJECTED' && (
                        <Alert
                          message="About your domain's language"
                          description={
                            <div>
                              The language{' '}
                              <Tag color="black">{domainDetails.language}</Tag>{' '}
                              is supported but{' '}
                              <b>
                                there are not a lot of domains using this
                                language on the platform.
                              </b>{' '}
                              You may not receive as relevant backlinks as we
                              aim to. Sorry for that! This is improving every
                              day.
                            </div>
                          }
                          type="info"
                          showIcon
                          style={{ border: 'none', marginBottom: 12 }}
                        />
                      )}
                    {domainDetails.status !== 'REJECTED' && (
                      <div className={styles.settingsContainer}>
                        <div className={styles.settingsWrapper}>
                          {domainDetails.isFastResponder && (
                            <div>
                              <Tooltip title="You are a fast responder. Others can see your badge on your domain details. You're more likely to receive requests now.">
                                <FastResponderBadge
                                  style={{ marginRight: 12 }}
                                />
                                &nbsp;
                              </Tooltip>
                            </div>
                          )}
                          <div>
                            <Button
                              type="link"
                              onClick={openSettingsModal}
                              icon={<SettingOutlined />}
                            >
                              {`Settings for ${domainDetails.name}`}
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}

                    {(domainDetails.status === 'TO_REVIEW' ||
                      domainDetails.status === 'TO_RE_REVIEW') && (
                      <div className={styles.inReviewWrapper}>
                        <div className={styles.inReviewInner}>
                          <HourglassTwoTone
                            twoToneColor={'#564e99'}
                            className={styles.inReviewIcon}
                          />
                          {domainDetails.verified ? (
                            <h2>We're currently reviewing this domain</h2>
                          ) : (
                            <h2>This domain is not yet verified</h2>
                          )}
                        </div>
                      </div>
                    )}
                    {domainDetails.status === 'REJECTED' && (
                      <div className={styles.rejectedWrapper}>
                        <div className={styles.rejectedInner}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'flex-start',
                            }}
                          >
                            <CloseCircleOutlined
                              twoToneColor={'red'}
                              className={styles.rejectedIcon}
                            />
                            <div style={{ flexGrow: 1 }}>
                              <h2>{getRejectionText()}</h2>
                              <p>The domain has been rejected.</p>
                            </div>
                          </div>

                          <div className={styles.rejectedCta}>
                            <Button
                              onClick={removeDomain}
                              loading={removeLoading}
                            >
                              Remove Domain
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                    {domainDetails.status === 'APPROVED' && (
                      <div>
                        {!domainDetails.isFastResponder &&
                          isInfoResponsivityOpen && (
                            <div>
                              <Alert
                                style={{
                                  background: '#fffbe3',
                                  border: '1px solid #e9e4c5',
                                }}
                                closable
                                onClose={() => {
                                  closeInfoResposivity();
                                }}
                                message={
                                  <div>
                                    <FastResponderBadge
                                      style={{ marginRight: 12 }}
                                    />
                                    <b>
                                      Earn this Fast Responder badge by
                                      responding to requests in less than 48h.
                                    </b>
                                  </div>
                                }
                              />
                            </div>
                          )}
                        <div className={styles.vignetteList}>
                          <Vignette
                            animate
                            figure={domainDetails.points}
                            name={
                              <span>
                                Karma Points&nbsp;
                                <KarmaIcon />
                              </span>
                            }
                            featured
                            extra={
                              <div>
                                <Button
                                  style={{ padding: 0, paddingRight: 12 }}
                                  type="link"
                                  onClick={() => {
                                    Modal.info({
                                      content: (
                                        <div>
                                          Please contact us at{' '}
                                          <Typography.Text code copyable>
                                            hey@karmalinks.io
                                          </Typography.Text>
                                          with the number of points you'd like
                                          to buy and the name of your domain.
                                        </div>
                                      ),
                                    });
                                  }}
                                >
                                  Buy points
                                </Button>
                              </div>
                            }
                          />
                          {domainDetails.metrics && (
                            <Vignette
                              figure={
                                <DomainRating
                                  value={domainDetails.metrics?.authority?.dr}
                                />
                              }
                              name={'Domain Rating'}
                            />
                          )}
                          <Vignette
                            figure={domainDetails?.receivedBacklinksCount || 0}
                            name={'Received Backlinks'}
                          />
                        </div>
                        <div>
                          <Tabs
                            onChange={onChangeTab}
                            activeKey={tabQuery}
                            items={[
                              domainDetails.live
                                ? {
                                    key: 'browse',
                                    label: `Browse backlinks opportunities`,
                                    children: (
                                      <BrowseBacklinksOpportunities
                                        domain={domainDetails}
                                        refreshDomainDetails={getDomainDetails}
                                      />
                                    ),
                                    icon: <SearchOutlined />,
                                  }
                                : {},
                              {
                                key: 'history',
                                label:
                                  pendingRequests.length > 0 ? (
                                    <Badge
                                      count={pendingRequests.length}
                                      offset={[0, -5]}
                                    >
                                      History of requests and backlinks
                                    </Badge>
                                  ) : (
                                    'History of requests and backlinks'
                                  ),
                                children: (
                                  <BacklinksHistory
                                    domain={domainDetails}
                                    refreshDomainDetails={getDomainDetails}
                                  />
                                ),
                                icon: <UnorderedListOutlined />,
                              },
                              // {
                              //   key: 'monitoring',
                              //   label: `Backlinks Monitoring`,
                              //   children: <BacklinksMonitoring />,
                              //   icon: <MonitorOutlined />,
                              // },
                            ]}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        {(forceOnboarding || newDomainFlow) && (
          <Onboarding
            firstOnboarding={forceOnboarding}
            close={
              newDomainFlow
                ? () => {
                    localStorage.removeItem(
                      LOCAL_STORAGE.CURRENT_DOMAIN_VALIDATION
                    );
                    setNewDomainFlow(false);
                  }
                : () => {}
            }
          />
        )}
        {domainDetails && settingsModalisOpen && (
          <DomainSettingsModal
            isOpen={settingsModalisOpen}
            onClose={closeSettingsModal}
            domain={domainDetails}
            getDomainDetails={getDomainDetails}
          />
        )}
      </>
    </div>
  );
};

export default Dashboard;
