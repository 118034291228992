/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from 'react';
import CategoryContext from 'services/categoryContext';
import ProfileContext from 'services/profileContext';
import styles from 'styles/components/onboarding.module.scss';
const { Form, Button, Select, Input, TreeSelect } = require('antd');

const DomainSettingsForm = ({
  onFinishSettings,
  domainData,
  loadingSettings,
  saveButtonText = 'Save this domain',
  disableAll = false,
  aiMentions = false,
}) => {
  const { profile } = useContext(ProfileContext);
  const { categories } = useContext(CategoryContext);

  return (
    <Form
      layout="vertical"
      onFinish={onFinishSettings}
      initialValues={{
        description: domainData.description,
        language: domainData.language,
        categories: domainData.categories,
      }}
    >
      <Form.Item
        className={styles.formItemSettings}
        label={'How would you describe the website?'}
        name="description"
        placeholder="Enter the Domain description"
        layout="vertical"
        rules={[
          {
            required: true,
            message: 'Please input the domain description',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value) {
                return Promise.resolve();
              } else if (value.length > 100) {
                return Promise.reject(
                  new Error('The description must not exceed 100 characters')
                );
              } else {
                return Promise.resolve();
              }
            },
          }),
        ]}
      >
        <Input.TextArea
          disabled={disableAll}
          size={'large'}
          placeholder="Enter the domain description"
        />
      </Form.Item>
      {aiMentions && (
        <div className={styles.aiPulled}>
          🔮 Data pulled from your website and processed with AI
        </div>
      )}
      <Form.Item
        className={styles.formItemSettings}
        label={"What's the domain's main language?"}
        name="language"
        placeholder="Enter the Domain's main language"
        layout="vertical"
        rules={[
          {
            required: true,
            message: "Please input the domain's main language",
          },
        ]}
      >
        <Select
          optionFilterProp="label"
          showSearch={true}
          disabled={disableAll}
          size="large"
          options={profile.ui.languages.map((l) => ({
            value: l.code,
            label: l.name,
          }))}
        />
      </Form.Item>
      {aiMentions && (
        <div className={styles.aiPulled}>
          🔮 Data pulled from your website and processed with AI
        </div>
      )}
      <Form.Item
        className={styles.formItemSettings}
        label={'Choose the categories that best describes the Domain'}
        name="categories"
        placeholder="Enter the Domain's main tags"
        layout="vertical"
        rules={[
          {
            required: true,
            message: "Please input the domain's main categories",
          },
        ]}
      >
        <TreeSelect
          disabled={disableAll}
          size="large"
          fieldNames={{
            children: 'subCategories',
            value: '_id',
            label: 'name',
          }}
          showSearch
          treeData={categories}
          filterTreeNode={(input, node) =>
            node.name.toLowerCase().includes(input)
          }
          multiple
          treeExpandAction={'click'}
          maxcount={10}
        />
      </Form.Item>
      {aiMentions && (
        <div className={styles.aiPulled}>
          🔮 Data pulled from your website and processed with AI
        </div>
      )}
      <Form.Item className={styles.formItemSettings} style={{ marginTop: 24 }}>
        <Button
          size={'large'}
          type="primary"
          loading={loadingSettings}
          htmlType={'submit'}
          block
        >
          {saveButtonText}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DomainSettingsForm;
