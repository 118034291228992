import { getApiURL, getSessionId } from 'utils/utils';
import axios from 'axios';

const instance = axios.create({
  // baseURL: 'http://localhost:9781',
  baseURL: getApiURL(),
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export const getDefaultSettings = (params) =>
  instance.post(`/web-app/users/domains/get-default-settings`, params, {
    headers: {
      sessionId: getSessionId(),
    },
  });
export const getCodeForDomain = (params) =>
  instance.post(`/web-app/users/domains/ask-code`, params, {
    headers: {
      sessionId: getSessionId(),
    },
  });
export const verifyByEmailCode = (params) =>
  instance.post(`/web-app/users/domains/verify-by-email`, params, {
    headers: {
      sessionId: getSessionId(),
    },
  });
export const verifyBacklink = (params) =>
  instance.post(`/web-app/users/domains/verify-by-backlink`, params, {
    headers: {
      sessionId: getSessionId(),
    },
  });
export const addDomain = (params) =>
  instance.post(`/web-app/users/domains/add`, params, {
    headers: {
      sessionId: getSessionId(),
    },
  });

export const pauseDomain = (params) =>
  instance.post(`/web-app/users/domains/pause`, params, {
    headers: {
      sessionId: getSessionId(),
    },
  });

export const resumeDomain = (params) =>
  instance.post(`/web-app/users/domains/unpause`, params, {
    headers: {
      sessionId: getSessionId(),
    },
  });
export const updateDomain = (params) =>
  instance.post(`/web-app/users/domains/update`, params, {
    headers: {
      sessionId: getSessionId(),
    },
  });

export const getAllDomains = () =>
  instance.post(`/web-app/users/domains/list`, undefined, {
    headers: {
      sessionId: getSessionId(),
    },
  });

export const getDetails = (params) =>
  instance.post(`/web-app/users/domains/get-details`, params, {
    headers: {
      sessionId: getSessionId(),
    },
  });
export const submitBacklink = (params) =>
  instance.post(`/web-app/users/domains/requests/submit-backlink`, params, {
    headers: {
      sessionId: getSessionId(),
    },
  });
export const fixBacklink = (params) =>
  instance.post(`/web-app/users/domains/fix-backlink`, params, {
    headers: {
      sessionId: getSessionId(),
    },
  });
///delete-unrviewed-domain
export const deleteUnreviewedBacklink = (params) =>
  instance.post(`/web-app/users/domains/delete-unreviewed-domain`, params, {
    headers: {
      sessionId: getSessionId(),
    },
  });
export const deleteRejectedBacklink = (params) =>
  instance.post(`/web-app/users/domains/delete-rejected-domain`, params, {
    headers: {
      sessionId: getSessionId(),
    },
  });
export const getCategories = () =>
  instance.post(`/web-app/users/domains/get-categories`, undefined, {
    headers: {
      sessionId: getSessionId(),
    },
  });

export const listOpportunities = (params) =>
  instance.post(`/web-app/users/domains/opportunities/list`, params, {
    headers: {
      sessionId: getSessionId(),
    },
  });

export const createRequest = (params) =>
  instance.post(`/web-app/users/domains/requests/create`, params, {
    headers: {
      sessionId: getSessionId(),
    },
  });
export const acceptRequest = (params) =>
  instance.post(`/web-app/users/domains/requests/accept`, params, {
    headers: {
      sessionId: getSessionId(),
    },
  });
export const rejectRequest = (params) =>
  instance.post(`/web-app/users/domains/requests/reject`, params, {
    headers: {
      sessionId: getSessionId(),
    },
  });
