/* eslint-disable react-hooks/exhaustive-deps */
import {
  // CalendarTwoTone,
  MailOutlined,
  NumberOutlined,
  // EyeTwoTone,
  // SafetyCertificateTwoTone,
} from '@ant-design/icons';
import { Button, Divider, Input, message, Modal } from 'antd';
import Form from 'antd/es/form/Form';
import Typography from 'antd/es/typography/Typography';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { submitInviteCode } from 'services/api/users';
import DomainContext from 'services/domainContext';
import ProfileContext from 'services/profileContext';
import styles from 'styles/components/onboarding.module.scss';
import { LOCAL_STORAGE } from 'utils/constants';

const UnderReview = ({ close }) => {
  const [whiteListModalOpen, setWhiteListModalOpen] = useState(false);
  const { domains } = useContext(DomainContext);
  const { profile, updateProfile } = useContext(ProfileContext);
  const [loading, setLoading] = useState(false);
  const [showHaveACodeForm, setShowHaveACodeForm] = useState(false);

  const navigate = useNavigate();

  const onFinishCode = (values) => {
    setLoading(true);
    submitInviteCode(values.code)
      .then(() => {
        message.success('Code submited successfully');
        return updateProfile();
      })
      .catch((e) => {
        console.error(e);
        message.error('Error while submitting code, please try again.');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // const openModalWhitelist = () => {
  //   setWhiteListModalOpen(true);
  // };

  const goToDashboard = () => {
    close();
    navigate(
      currentDomain && currentDomain?.name.length > 0
        ? `/dashboard?domain=${currentDomain.name}`
        : '/dashboard'
    );
  };

  const [currentDomain, setCurrentDomain] = useState({});
  const currentDomainName = localStorage.getItem(
    LOCAL_STORAGE.CURRENT_DOMAIN_VALIDATION
  );

  useEffect(() => {
    const currentDomainFound = domains.find(
      (d) => d.name === currentDomainName
    );
    setCurrentDomain(currentDomainFound);
  }, [domains]);

  const hasOneValidatedDomain =
    domains?.filter((a) => a.status === 'APPROVED')?.length > 0;
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Sun%20with%20Face.png"
          alt="Sun with Face"
          width="150"
          height="150"
        />
      </div>
      <h1 className={styles.title}>
        {hasOneValidatedDomain
          ? 'Your new domain is under review'
          : 'Your account is under review'}
      </h1>
      <div style={{ textAlign: 'center' }}>
        {hasOneValidatedDomain
          ? "We’re reviewing your domain and will let you know once it's accepted."
          : 'We’re reviewing your account and will let you know once you’re accepted.'}

        <br />
      </div>
      {profile.hasBeenInvited && (
        <div>
          <Divider style={{ borderColor: '#464646' }} />
          <h2
            className={styles.inviteTitle}
            style={{ marginTop: 22, color: 'white' }}
          >
            ⚡️&nbsp;Your invitation code was submitted&nbsp; ⚡️
          </h2>
        </div>
      )}
      {!hasOneValidatedDomain && (
        <>
          <Divider style={{ borderColor: '#464646' }} />
          <div className={styles.inviteWrapper}>
            <h2 className={styles.inviteTitle}>
              <MailOutlined />
              &nbsp; Invite your friends
            </h2>
            <div>
              {profile.hasBeenInvited ? (
                <div>
                  <b>⚡️&nbsp;Fastrack</b> your friend's review by sharing your
                  invite link with them.
                </div>
              ) : (
                <div>
                  <b>⚡️&nbsp;Fastrack</b> your own review by inviting others.
                </div>
              )}
            </div>
            <div style={{ marginTop: 14 }}>
              Here is your invite link: <br />
              <Typography.Text copyable code>
                {`https://karmalinks.io/?ref=${profile.inviteCode}`}
              </Typography.Text>
            </div>

            {!showHaveACodeForm && (
              <div
                style={{ marginTop: 16 }}
                onClick={() => {
                  setShowHaveACodeForm(true);
                }}
              >
                <Button type="link">Someone invited you?</Button>
              </div>
            )}

            {showHaveACodeForm && !profile.hasBeenInvited && (
              <>
                <Divider style={{ borderColor: '#464646' }} />
                <h2 className={styles.inviteTitle}>☝️&nbsp;Have a code?</h2>
                <div>Enter your invite code here to fastrack your review.</div>

                <div className={styles.codeFormWrapper}>
                  <Form
                    layout="inline"
                    style={{ justifyContent: 'center' }}
                    onFinish={onFinishCode}
                  >
                    <Form.Item
                      label={'Have a code?'}
                      name="code"
                      placeholder="Enter the invite code"
                      layout="vertical"
                      rules={[
                        {
                          required: true,
                          message: 'Please input the invite code',
                        },
                      ]}
                    >
                      <Input
                        size={'large'}
                        prefix={<NumberOutlined />}
                        placeholder="123456"
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        size={'large'}
                        type="primary"
                        loading={loading}
                        htmlType={'submit'}
                        style={{
                          marginTop: 28,
                        }}
                      >
                        ⚡️&nbsp;Fastrack
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </>
            )}
          </div>
        </>
      )}

      {hasOneValidatedDomain && (
        <div style={{ textAlign: 'center', marginTop: 26 }}>
          <Button
            type="primary"
            onClick={goToDashboard}
            size="large"
            style={{ minWidth: 300 }}
          >
            Go to Dashboard
          </Button>
        </div>
      )}

      <Modal
        open={whiteListModalOpen}
        onCancel={() => {
          setWhiteListModalOpen(false);
        }}
        okText={'Close'}
        footer={
          <Button
            type="primary"
            onClick={() => {
              setWhiteListModalOpen(false);
            }}
          >
            Close
          </Button>
        }
        onOk={() => {
          setWhiteListModalOpen(false);
        }}
        title={'How to whitelist Karmalinks.io email'}
      >
        Follow these steps if you're using Gmail/Google Workspace:
        <ul>
          <li>
            Click the gear icon in Gmail and select "See all settings" from the
            Quick settings menu.
            <div>
              <img src="/img/whitelist.png" alt="whitelist email" />
            </div>
          </li>
          <li>Navigate to "Filters and Blocked Addresses" in the top menu.</li>
          <li>Select "Create a new filter."</li>
          <li>Add a specific email or a whole domain in the "From" field.</li>
          <li>Click "Create filter."</li>
          <li>Whitelist backlinkrequest@karmalinks.io</li>
        </ul>
      </Modal>
    </div>
  );
};
export default UnderReview;
