export const API_DEV_URL = 'https://api.karmalinks.io';
export const API_URL = 'https://api.karmalinks.io';

export const EMAIL_DOMAIN_STATUS = {
  COMPANY: 'COMPANY',
};

export const LOCAL_STORAGE = {
  CURRENT_DOMAIN_VALIDATION: 'CURRENT_DOMAIN_VALIDATION',
  INFO_RESPONSIVITY: 'INFO_RESPONSIVITY',
};

export const CRISP_WEBSITE_ID_PERS = 'bea2794c-105c-469d-9a50-07179561f09e';

export const BACKLINK_REQUEST_STATUS = {
  ACCEPTED: 'ACCEPTED',
  PENDING: 'PENDING',
  LIVE: 'LIVE',
  WARNING: 'WARNING',
  LOST: 'LOST',
  EXPIRED: 'EXPIRED',
};

export const REQUEST_TYPE = {
  GIVE: 'GIVE',
  ASK: 'RECEIVE',
};

export const MAX_CONCURRENT_REQUESTS = 2;
export const MAX_DAYS_TO_ANSWER = 7;
export const GA_MEASUREMENT_ID = 'G-Y5VXHL3J53';

export const ANALYTICS_EVENTS = {
  SIGNUP: 'SIGNUP',
};