const KarmaIcon = ({ fade = false }) => (
  <img
    src="/img/karma_icon_80.svg"
    alt="karma"
    style={{
      opacity: fade ? 0.2 : 1,
      userSelect: 'none',
      width: 16,
      display: 'inline-block',
      verticalAlign: 'sub',
    }}
  />
);

export default KarmaIcon;
