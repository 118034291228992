import { ThunderboltFilled } from '@ant-design/icons';
import { Tag } from 'antd';
import styles from 'styles/components/fastResponderBadge.module.scss';

const FastResponderBadge = ({ style = {} }) => (
  <Tag className={styles.responsive} style={style}>
    <ThunderboltFilled style={{ marginRight: 4, fontSize: 12 }} />
    RESPONSIVE
  </Tag>
);

export default FastResponderBadge;
