/* eslint-disable react-hooks/exhaustive-deps */
import {
  ArrowRightOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  LinkOutlined,
} from '@ant-design/icons';
import {
  Button,
  Divider,
  Empty,
  Form,
  Input,
  message,
  Modal,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import {
  acceptRequest,
  fixBacklink,
  rejectRequest,
  submitBacklink,
} from 'services/api/domains';
import DomainContext from 'services/domainContext';
import styles from 'styles/components/backlinksHistory.module.scss';
import { BACKLINK_REQUEST_STATUS, MAX_DAYS_TO_ANSWER } from 'utils/constants';
import {
  getCountLabel,
  getLinkStatusTag,
  getStatusDetails,
  getStatusTag,
  hostnameToURL,
  useWindowSize,
} from 'utils/utils';
import DomainMetrics from './domainMetrics';
import { useSearchParams } from 'react-router-dom';
import pluralize from 'pluralize';
import KarmaIcon from './karmaIcon';
import DomainName from './domainName';
import HistoryMobile from './historyMobile';
import ConfettiExplosion from 'react-confetti-explosion';
import CategoryContext from 'services/categoryContext';
import Instructions from './instructions';

const BacklinksHistory = ({ domain, refreshDomainDetails }) => {
  const [respondModalOpen, setRespondModalOpen] = useState(false);
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [isConfettiExploding, setIsConfettiExploding] = useState(false);

  const pendingRequests = domain?.requests?.filter(
    (e) => e.status === 'PENDING' || e.status === 'ACCEPTED'
  );
  const otherRequests = domain.requests.filter(
    (e) => e.status !== 'PENDING' && e.status !== 'ACCEPTED'
  );
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [submitModalOpen, setSubmitModalOpen] = useState(null);
  const [instructions, setInstructions] = useState(null);
  const [respondModalCategories, setRespondModalCategories] = useState(null);
  const { categoriesFlat } = useContext(CategoryContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const size = useWindowSize();
  const mobile = size.width < 650;

  const { updateDomains } = useContext(DomainContext);

  useEffect(() => {
    const requestIdParam = searchParams.get('requestId');
    const actionParam = searchParams.get('action');
    if (
      actionParam &&
      actionParam.length > 0 &&
      requestIdParam?.length > 0 &&
      domain &&
      domain.requests &&
      categoriesFlat &&
      !respondModalOpen
    ) {
      // actionParam
      const request = domain.requests.find((r) => r._id === requestIdParam);
      if (request && request.initiatedBy !== domain._id) {
        openRespond(request);

        if (actionParam && actionParam.length > 0) {
          if (actionParam === 'accept') {
            accept(request);
          } else if (actionParam === 'reject') {
            setOpenRejectModal(true);
          }
        }
      }
    }
  }, [searchParams, domain, categoriesFlat]);

  const accept = (element = respondModalOpen) => {
    setLoadingAccept(true);
    acceptRequest({
      requestId: element._id,
      ...(instructions ? { instructions: instructions } : {}),
    })
      .then(() => {
        setTimeout(() => {
          setLoadingAccept(false);
          message.success('Request accepted successfully');
          refreshDomainDetails();
          closeRepondModal();
          updateDomains();
        }, 1000);
      })
      .catch((e) => {
        setLoadingAccept(false);
        console.error(e);
        if (e.response.data.error === 'BAD_INSTRUCTIONS') {
          message.error(
            'Issue with instructions. Sending contact information is strictly forbidden.'
          );
        } else {
          message.error('Error while accepting the request, please try again.');
        }
      });
  };

  const reject = (values) => {
    setLoadingReject(true);
    rejectRequest({ requestId: respondModalOpen._id, reason: values.reason })
      .then(() => {
        message.success('Request rejected successfully');
        refreshDomainDetails();
        closeRepondModal();
        setOpenRejectModal(false);
        updateDomains();
      })
      .catch((e) => {
        message.error('Error while rejecting the request, please try again.');
        console.error(e);
      })
      .finally(() => {
        setLoadingReject(false);
      });
  };

  const getDomainName = (d) => (
    <DomainName domain={d === domain._id ? domain : d} />
  );

  const columns = [
    {
      title: (
        <>
          Domain (Backlink From
          <ArrowRightOutlined style={{ marginLeft: 4, marginRight: 4 }} />
          To)
        </>
      ),
      dataIndex: 'description',
      key: 'description',
      width: 220,
      render: (s, el) => (
        <>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div style={el.from === domain._id ? {} : { fontWeight: 'bold' }}>
              {getDomainName(el.from)}
            </div>
            <ArrowRightOutlined style={{ marginLeft: 4, marginRight: 4 }} />
            <div style={el.to === domain._id ? {} : { fontWeight: 'bold' }}>
              {el.to.name ? (
                <Typography.Text copyable={{ text: hostnameToURL(el.to.name) }}>
                  <b>{`${el.to.name}`}</b>
                </Typography.Text>
              ) : (
                getDomainName(el.to)
              )}
            </div>
          </div>
          <Divider style={{ margin: '4px 0px' }} />

          {el.from === domain._id ? (
            <div>{el.to.description}</div>
          ) : (
            el.from.description
          )}
          <div className={styles.requestedBy}>
            Requested by{' '}
            {getDomainName(
              el.initiatedBy === domain._id
                ? domain
                : el.initiatedBy === el.from?._id
                ? el.from
                : el.to
            )}
          </div>
        </>
      ),
    },
    {
      title: 'Metrics',
      dataIndex: 'metrics',
      key: 'metrics',
      width: 130,
      render: (s, el) => (
        <div style={{ color: '#1da87f' }}>
          {el.to === domain._id ? (
            <b>
              DR {el.from.metrics.authority.dr} / Traffic{' '}
              {getCountLabel(el.from.metrics.traffic.organic)}
            </b>
          ) : (
            <b>
              DR {el.to.metrics.authority.dr} / Traffic{' '}
              {getCountLabel(el.to.metrics.traffic.organic)}
            </b>
          )}
        </div>
      ),
    },
    {
      title: 'Created On',
      dataIndex: 'startedAt',
      key: 'startedAt',
      width: 120,
      render: (s, el) => (
        <div>
          <div>{moment(el.startedAt).format('Do MMM YYYY')}</div>
          {(el.status === 'PENDING' || el.status === 'ACCEPTED') && (
            <div style={{ fontWeight: 'bold' }}>
              {`${
                MAX_DAYS_TO_ANSWER -
                Math.abs(
                  moment(el.acceptedAt || el.startedAt).diff(
                    moment(new Date()),
                    'days'
                  )
                )
              } ${pluralize(
                'days',
                MAX_DAYS_TO_ANSWER -
                  Math.abs(
                    moment(el.acceptedAt || el.startedAt).diff(
                      moment(new Date()),
                      'days'
                    )
                  )
              )} left to answer`}
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'Your Points',
      dataIndex: 'points',
      key: 'points',
      width: 100,
      render: (s, el) =>
        el.status === 'EXPIRED' ? (
          (el.from === domain._id && el.acceptedAt) ||
          (!el.acceptedAt && el.initiatedBy !== domain._id) ? (
            <b style={{ color: '#d93a3a' }}>
              -{el.penalty}&nbsp; <KarmaIcon />
            </b>
          ) : (
            <b style={{ color: '#16b116' }}>
              +{el.penalty}&nbsp; <KarmaIcon />
            </b>
          )
        ) : el.status === 'REJECTED' ? (
          <span>
            0&nbsp;
            <KarmaIcon />
          </span>
        ) : (
          <b>
            {el.to === domain._id ? (
              <span style={{ color: '#d93a3a' }}>{`-${s}`}</span>
            ) : (
              <span style={{ color: '#16b116' }}>{`+${s}`}</span>
            )}
            &nbsp; <KarmaIcon />
          </b>
        ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (s, el) => (
        <div>
          {el.linkStatus ? getLinkStatusTag(el) : getStatusTag(s)}
          <div>{getStatusDetails(domain, el)}</div>
        </div>
      ),
      fixed: 'right',
      width: 150,
    },
  ];

  const closeRepondModal = () => {
    setRespondModalOpen(false);
    setInstructions(null);
    setSearchParams((prev) => {
      prev.delete('requestId');
      prev.delete('action');
      return prev;
    });
  };
  const submitBacklinkFunction = (values) => {
    try {
      new URL(values.url);
    } catch (e) {
      message.error(
        'This is not a valid URL, please enter a valid link (starting with https://).'
      );
      return;
    }
    setLoadingSubmit(true);
    const functionToCall =
      submitModalOpen.status === BACKLINK_REQUEST_STATUS.LOST
        ? fixBacklink
        : submitBacklink;
    functionToCall({
      requestId: submitModalOpen._id,
      url: values.url,
    })
      .then(() => {
        setSubmitModalOpen(false);
        message.success('Backlink submitted successsfully');
        showConfettis();
        refreshDomainDetails();
        updateDomains();
        form.resetFields();
      })
      .catch((e) => {
        console.error(e);
        if (e.response.data.error === 'LINK_NOT_FOUND') {
          message.error('Link was not found, verify the link and try again.');
        } else if (e.response.data.error === 'LINK_REL_NO_FOLLOW') {
          message.error(
            'The backlink has a rel="no-follow" attribute. Please remove it and re-submit the backlink.'
          );
        } else if (e.response.data.error === 'URL_MISMATCH_DOMAIN_NAME') {
          message.error(
            'The domain is not correct. Try again or contact support.'
          );
        } else {
          message.error(
            'Error while submitting backlink. Try again or contact support.'
          );
        }
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };

  const showConfettis = () => {
    setIsConfettiExploding(true);
  };
  const onChangeInstructions = (e) => {
    setInstructions(e.target.value);
  };

  const openRespond = (element) => {
    const d = element.to !== domain._id ? element.to : element.from;
    const categoriesDomain = categoriesFlat
      ? d.categories.map((r) => categoriesFlat.find((c) => c._id === r))
      : d.categories;
    setRespondModalCategories(categoriesDomain);
    setRespondModalOpen(element);
  };

  const [form] = Form.useForm();

  const columnsPending = [
    ...columns,
    {
      dataIndex: 'actions',
      key: 'actions',
      render: (s, el) =>
        el.status === 'ACCEPTED' && el.to !== domain._id ? (
          <div style={{ marginTop: 4 }}>
            <Button
              type="primary"
              onClick={() => {
                setSubmitModalOpen(el);
              }}
            >
              Submit Backlink
            </Button>
            <br />
            <Tooltip
              color="black"
              title={
                <div>
                  Any issue with the backlink?
                  <br />
                  Or if you think you won’t be able to ship it in time.
                  <br />
                  <br />
                  Please contact us by clicking on the chat bot icon at the
                  bottom right of the page or contact us hello@karmalinks.io
                </div>
              }
            >
              <span style={{ color: '#676767', marginRight: 12 }}>
                Any issue?
              </span>
            </Tooltip>
          </div>
        ) : el.initiatedBy !== domain._id && el.status === 'PENDING' ? (
          <Button
            type="primary"
            onClick={() => {
              openRespond(el);
            }}
          >
            Respond
          </Button>
        ) : (
          <></>
        ),
      fixed: 'right',
      width: 140,
    },
  ];
  return (
    <div className={styles.wrapper}>
      {isConfettiExploding && (
        <div style={{ position: 'fixed', top: 0, left: '45%' }}>
          <ConfettiExplosion
            particleCount={300}
            onComplete={() => {
              setIsConfettiExploding(false);
            }}
          />
        </div>
      )}
      {pendingRequests && pendingRequests.length > 0 && (
        <div className={styles.pendingWrapper}>
          <h1>{`Pending requests (${pendingRequests.length})`}</h1>
          <div>
            {mobile ? (
              <HistoryMobile
                requests={pendingRequests}
                columns={columnsPending}
              />
            ) : (
              <Table
                rowKey={'_id'}
                locale={{ emptyText: 'No requests yet' }}
                dataSource={pendingRequests}
                columns={columnsPending}
                pagination={false}
                scroll={{
                  x: 700,
                }}
              />
            )}
          </div>
        </div>
      )}

      {otherRequests && otherRequests.length > 0 && (
        <div className={styles.otherRequestsWrapper}>
          <h1>{`History`}</h1>
          {mobile ? (
            <HistoryMobile requests={otherRequests} columns={columns} />
          ) : (
            <Table
              rowKey={'_id'}
              locale={{ emptyText: 'No requests yet' }}
              dataSource={otherRequests}
              columns={columns}
              pagination={false}
              scroll={{
                x: 600,
              }}
            />
          )}
        </div>
      )}
      {domain?.requests?.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No requests to list yet"
        />
      )}
      <Modal
        open={respondModalOpen}
        title="Respond to a backlink request"
        footer={null}
        onCancel={closeRepondModal}
        width={400}
      >
        {respondModalOpen && (
          <>
            <div>
              {respondModalOpen.to !== domain._id ? (
                <>
                  <b>{respondModalOpen.to.name}</b>&nbsp;is asking a backlink
                  from you.
                  <br />
                  <Divider />
                  {respondModalOpen && (
                    <DomainMetrics
                      domain={respondModalOpen.to}
                      categories={respondModalCategories || []}
                      canSeeYCCompanies={domain.isYC}
                    />
                  )}
                  <Divider />
                  <b>
                    Are you ready to give a backlink to{' '}
                    {respondModalOpen.to.name}?
                  </b>
                  <div>
                    <b>Instructions</b>
                    {respondModalOpen.instructions &&
                    respondModalOpen.instructions.length > 0 ? (
                      <div>{respondModalOpen.instructions}</div>
                    ) : (
                      <div>No instructions were left</div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <b>{respondModalOpen.from.name}</b>&nbsp;wants to give you a
                  backlink.
                  <br />
                  <Divider />
                  {respondModalOpen && (
                    <DomainMetrics
                      domain={respondModalOpen.from}
                      categories={respondModalCategories || []}
                      canSeeYCCompanies={domain.isYC}
                    />
                  )}
                  <Divider />
                  <b>
                    Are you ready to receive a backlink from{' '}
                    {respondModalOpen.from.name}?
                  </b>
                  <Instructions onChangeInstructions={onChangeInstructions} />
                </>
              )}
            </div>
            <div className={styles.footer}>
              <div className={styles.footerElement}>
                <Button
                  block
                  type="danger"
                  style={{
                    borderColor: 'red',
                    color: 'white',
                    background: '#d00000',
                  }}
                  loading={loadingReject}
                  disabled={loadingAccept}
                  onClick={() => {
                    setOpenRejectModal(true);
                  }}
                  icon={<CloseCircleFilled />}
                >
                  Reject
                </Button>
              </div>
              <div className={styles.footerElement}>
                <Button
                  block
                  type="primary"
                  loading={loadingAccept}
                  disabled={loadingReject}
                  onClick={() => {
                    accept();
                  }}
                  icon={<CheckCircleFilled />}
                >
                  {respondModalOpen.to === domain._id ? (
                    <div>
                      Accept&nbsp;
                      <b>
                        {` -${respondModalOpen.points} `} <KarmaIcon />
                      </b>
                    </div>
                  ) : (
                    <div>
                      Accept&nbsp;
                      <b>
                        {` +${respondModalOpen.points} `} <KarmaIcon />
                      </b>
                    </div>
                  )}
                </Button>
              </div>
            </div>
            {respondModalOpen.to === domain._id && (
              <div className={styles.hintRequest}>
                The points are already on hold, you'll be refunded if you choose
                to reject the request.
              </div>
            )}
          </>
        )}
      </Modal>
      <Modal
        title="Submit a backlink"
        onCancel={() => setSubmitModalOpen(false)}
        open={!!submitModalOpen}
        footer={null}
      >
        <br />
        <div>
          Backlink to <b>{submitModalOpen?.to?.name}</b>
        </div>
        <Form
          layout="vertical"
          onFinish={submitBacklinkFunction}
          style={{ marginTop: 28 }}
          form={form}
        >
          <Form.Item
            label={'Enter the backlink URL'}
            name="url"
            layout="vertical"
            rules={[
              {
                required: true,
                message: 'Please input the backlink URL',
              },
            ]}
          >
            <Input
              size={'large'}
              prefix={<LinkOutlined />}
              placeholder={`Enter a backlink URL to ${submitModalOpen?.to?.name}`}
            />
          </Form.Item>
          <Form.Item>
            <Button
              size={'large'}
              type="primary"
              loading={loadingSubmit}
              htmlType={'submit'}
              block
            >
              Submit Backlink
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Reject a request"
        onCancel={() => setOpenRejectModal(false)}
        footer={null}
        open={openRejectModal}
      >
        <Form
          layout="vertical"
          onFinish={reject}
          style={{ marginTop: 28 }}
          form={form}
        >
          <Form.Item
            style={{ marginBottom: 4 }}
            label={'Enter a reason for the rejection'}
            name="reason"
            layout="vertical"
            rules={[
              {
                required: true,
                message: 'Please enter a reason for the rejection',
              },
            ]}
          >
            <Input
              size={'large'}
              prefix={<LinkOutlined />}
              placeholder={`Enter a reason for the rejection`}
            />
          </Form.Item>
          <div style={{ color: 'grey', marginBottom: 20 }}>
            The reason is only shared with KarmaLinks admins.
          </div>
          <Form.Item>
            <Button
              block
              htmlType="submit"
              type="danger"
              style={{
                borderColor: 'red',
                color: 'white',
                background: '#d00000',
              }}
              loading={loadingReject}
              disabled={loadingAccept}
              icon={<CloseCircleFilled />}
            >
              Reject
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default BacklinksHistory;
