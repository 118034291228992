import React from 'react';

const ProfileContext = React.createContext({
  profile: {},
  setProfile: () => {},
  updateProfile: () => {},
  clearProfile: () => {},
});

export default ProfileContext;
