/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  CheckCircleFilled,
  FilterOutlined,
  InfoCircleFilled,
  LikeFilled,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Empty, Input, Tooltip, TreeSelect } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { listOpportunities } from 'services/api/domains';
import styles from 'styles/components/browseBacklinksOpportunities.module.scss';
import Loader from './loader';
import RequestBacklinkModal from './requestBacklinkModal';
import { MAX_CONCURRENT_REQUESTS, REQUEST_TYPE } from 'utils/constants';
import { useSearchParams } from 'react-router-dom';
import DomainMetrics from './domainMetrics';
import KarmaIcon from './karmaIcon';
import pluralize from 'pluralize';
import CategoryContext from 'services/categoryContext';

const BrowseBacklinksOpportunities = ({ domain, refreshDomainDetails }) => {
  const [onlyGoodMatchFilter, setOnlyGoodMatchFilter] = useState(false);
  const [onlyAvailableFilter, setOnlyAvailableFilter] = useState(true);
  const [onlyYCFilter, setOnlyYCFilter] = useState(false);

  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [loadingOpportunities, setLoadingOpportunities] = useState(false);
  const { categories, categoriesFlat } = useContext(CategoryContext);
  const [opportunities, setOpportunities] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [requestType, setRequestType] = useState(REQUEST_TYPE.GIVE);
  const [searchInputValue, setSearchInputValue] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filteredOpportunities, setFilteredOpportunities] = useState(null);

  const listOpportunitiesCall = () => {
    setLoadingOpportunities(true);
    listOpportunities({
      domain: domain.name,
      categories: selectedCategories,
    })
      .then(({ data }) => {
        setOpportunities(data);
        filterOpportunities(data);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoadingOpportunities(false);
      });
  };

  const init = () => {
    if (!opportunities && !loadingOpportunities) {
      listOpportunitiesCall();
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    listOpportunitiesCall();
  }, [domain]);

  useEffect(() => {
    const categParams = searchParams.get('categories');
    if (categParams?.length > 0) {
      setSelectedCategories(categParams.split(','));
    }
    if (categParams?.length === 0 && categParams !== null) {
      setSelectedCategories([]);
    }
    if (!categParams) {
      setSelectedCategories([]);
    }

    // const textSearchParams = searchParams.get('search');
    // setSearchInputValue(textSearchParams);

    const isGoodMatchParams = searchParams.get('onlyGoodMatch');
    if (isGoodMatchParams && isGoodMatchParams.length > 0) {
      setOnlyGoodMatchFilter(isGoodMatchParams === 'false' ? false : true);
    } else {
      setOnlyGoodMatchFilter(false);
    }

    const isAvailableParams = searchParams.get('onlyAvailable');
    if (isAvailableParams && isAvailableParams.length > 0) {
      setOnlyAvailableFilter(isAvailableParams === 'false' ? false : true);
    } else {
      setOnlyAvailableFilter(true);
    }
    const onlyYCParams = searchParams.get('onlyYC');
    if (onlyYCParams && onlyYCParams.length > 0) {
      setOnlyYCFilter(onlyYCParams === 'false' ? false : true);
    } else {
      setOnlyYCFilter(false);
    }
  }, [searchParams]);

  const onChange = (value) => {
    setSearchParams((prev) => {
      prev.set('categories', value);
      return prev;
    });
  };

  useEffect(() => {
    listOpportunitiesCall();
  }, [selectedCategories]);

  useEffect(() => {
    filterOpportunities();
  }, [
    searchInputValue,
    onlyGoodMatchFilter,
    onlyAvailableFilter,
    onlyYCFilter,
  ]);

  const filterOpportunities = (opps = null) => {
    let filtered = opps || opportunities;

    if (searchInputValue && searchInputValue.length > 0) {
      filtered = filtered?.filter(
        (e) => `${e.description.toLowerCase()}`.search(searchInputValue) !== -1
      );
    }
    if (onlyGoodMatchFilter) {
      filtered = filtered?.filter((e) => getIsGoodMatch(e));
    }
    if (onlyAvailableFilter) {
      filtered = filtered?.filter((e) => getIsAvailable(e));
    }
    if (onlyYCFilter && domain.isYC) {
      filtered = filtered?.filter((e) => e.isYC);
    }
    setFilteredOpportunities(filtered);
  };

  const getIsAvailable = (opportunity) =>
    (opportunity.canGive || opportunity.canReceive) &&
    !opportunity.backlinkRequest;

  const onChangeSearchInputValue = (e) => {
    setSearchInputValue(e.target.value);
  };

  const openRequestModal = (dom, type) => {
    setRequestType(type);
    setRequestModalOpen(dom);
  };
  const setRequestModalClose = () => {
    setRequestModalOpen(null);
    listOpportunitiesCall();
  };

  const getIsGoodMatch = (opportunity) => {
    const categoriesInCommon = domain.categories.filter((value) =>
      opportunity.categories.includes(value)
    );
    if (
      Math.abs(
        domain.metrics.authority.dr - opportunity.metrics.authority.dr
      ) <= 10 &&
      categoriesInCommon.length > 0
    ) {
      return true;
    }
    return false;
  };

  const onChangeOnlyGoodMatch = (e) => {
    // setOnlyGoodMatchFilter(!onlyGoodMatchFilter);
    setSearchParams((prev) => {
      prev.set('onlyGoodMatch', e.target.checked);
      return prev;
    });
  };

  const onChangeOnlyAvailable = (e) => {
    // setOnlyGoodMatchFilter(!onlyGoodMatchFilter);
    setSearchParams((prev) => {
      prev.set('onlyAvailable', e.target.checked);
      return prev;
    });
  };

  const onChangeOnlyYC = (e) => {
    // setOnlyGoodMatchFilter(!onlyGoodMatchFilter);
    setSearchParams((prev) => {
      prev.set('onlyYC', e.target.checked);
      return prev;
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.filters}>
        <div className={styles.filter}>
          <TreeSelect
            className={styles.categorySelectFilter}
            allowClear={true}
            prefix={<FilterOutlined />}
            onChange={onChange}
            multiple
            style={{ width: 400, maxWidth: '100%' }}
            fieldNames={{
              children: 'subCategories',
              value: '_id',
              label: 'name',
            }}
            placeholder="Select a category"
            showSearch
            treeData={categories}
            filterTreeNode={(input, node) =>
              node.name.toLowerCase().includes(input)
            }
            treeExpandAction={'click'}
            value={selectedCategories}
            disabled={!opportunities}
          />
        </div>
        <div className={styles.filter}>
          <Input
            placeholder="Search keywords from description"
            value={searchInputValue}
            onChange={onChangeSearchInputValue}
            prefix={<SearchOutlined />}
            className={styles.searchInput}
            allowClear={true}
          />
        </div>

        <div className={styles.filter}>
          <Tooltip
            title={
              <div>
                Good match domains have at least one category in common and has
                a DR close to yours.
              </div>
            }
          >
            <div
              className={styles.onlyGoodMatchButton}
              onClick={onChangeOnlyGoodMatch}
            >
              <Checkbox
                checked={onlyGoodMatchFilter}
                style={{ marginRight: 6 }}
              />
              <LikeFilled style={{ marginRight: 4 }} />
              Only Good Matches
            </div>
          </Tooltip>
        </div>
        <div className={styles.filter}>
          <div
            className={styles.onlyAvailableButton}
            onClick={onChangeOnlyAvailable}
          >
            <Checkbox
              checked={onlyAvailableFilter}
              style={{ marginRight: 6 }}
            />
            <CheckCircleFilled style={{ marginRight: 4 }} />
            Only Available
          </div>
        </div>
        {domain.isYC && (
          <div className={styles.filter}>
            <Tooltip title={<div>Filter only available to YC companies.</div>}>
              <div className={styles.onlyYCButton} onClick={onChangeOnlyYC}>
                <Checkbox checked={onlyYCFilter} style={{ marginRight: 6 }} />
                <img src="/img/yc.png" className={styles.ycLogo} alt="yc" />
                Only YC companies&nbsp;
                <InfoCircleFilled />
              </div>
            </Tooltip>
          </div>
        )}
        <div className={styles.filter}>
          <a
            onClick={() => {
              //add search params domain.categories
              setSearchParams((prev) => {
                prev.set('categories', domain.categories);
                return prev;
              });
            }}
          >
            <FilterOutlined /> Only my categories
          </a>
        </div>
        <div className={styles.filterRight}>
          {filteredOpportunities && (
            <div className={styles.filter}>
              <span>{`${filteredOpportunities.length} ${pluralize(
                'result',
                filteredOpportunities.length
              )}`}</span>
            </div>
          )}
        </div>
      </div>
      {filteredOpportunities?.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No Opportunities to list yet, try changing the filters"
        />
      )}
      <div className={styles.list}>
        {filteredOpportunities?.map((element) => {
          const categoriesDomain = categoriesFlat
            ? element.categories.map((r) =>
                categoriesFlat?.find((c) => c._id === r)
              )
            : element.categories;

          const isGoodMatch = getIsGoodMatch(element);

          return (
            <div className={styles.vignetteWrapper} key={element._id}>
              <div className={styles.vignetteInner}>
                <div className={styles.domainDetailsWrapper}>
                  <DomainMetrics
                    domain={element}
                    categories={categoriesDomain}
                    isGoodMatch={isGoodMatch}
                    canSeeYCCompanies={domain.isYC}
                  />
                </div>

                <div className={styles.vignetteCTAs}>
                  <div className={styles.vignetteCTA}>
                    <div className={styles.vignetteCTAInner}>
                      <Tooltip
                        title={
                          element.backlinkRequest ? (
                            'You already exchanged backlinks with this domain'
                          ) : element.metrics.authority.dr > domain.points ? (
                            "You don't have enough points"
                          ) : element.pendingGiveRequestsCount >= 2 ? (
                            'You reached your maximum concurrent requests (2)'
                          ) : !element.canReceive ? (
                            <div>
                              Either you don't have enough points or you're at
                              the maximum concurrent requests. <br />
                              <br />
                              You can only request 2 backlinks at a time. This
                              measure prevents spamming.
                            </div>
                          ) : (
                            ''
                          )
                        }
                      >
                        <Button
                          block
                          disabled={
                            !element.canReceive || !!element.backlinkRequest
                          }
                          onClick={() =>
                            openRequestModal(element, REQUEST_TYPE.ASK)
                          }
                        >
                          Ask
                          <b>{`  -${element.metrics.authority.dr} `}</b>
                          <KarmaIcon
                            fade={
                              !element.canReceive || !!element.backlinkRequest
                            }
                          />
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                  <div className={styles.vignetteCTA}>
                    <div className={styles.vignetteCTAInner}>
                      <Tooltip
                        title={
                          domain.pendingGiveRequestsCount >=
                          MAX_CONCURRENT_REQUESTS
                            ? 'You have reached your maximum concurrent requests (2/2)'
                            : element.backlinkRequest
                            ? 'You already exchanged backlinks with this domain'
                            : !element.canGive
                            ? "This website can't receive requests at the moment. They may not have enough points."
                            : ''
                        }
                      >
                        <Button
                          type="primary"
                          block
                          disabled={
                            !element.canGive ||
                            !!element.backlinkRequest ||
                            domain.pendingGiveRequestsCount >=
                              MAX_CONCURRENT_REQUESTS
                          }
                          onClick={() =>
                            openRequestModal(element, REQUEST_TYPE.GIVE)
                          }
                        >
                          Give
                          <b>{`  +${domain.metrics.authority.dr} `}</b>
                          <KarmaIcon
                            fade={!element.canGive || !!element.backlinkRequest}
                          />
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <RequestBacklinkModal
          domain={domain}
          opportunity={requestModalOpen}
          isOpen={!!requestModalOpen}
          onClose={setRequestModalClose}
          categories={categoriesFlat}
          type={requestType}
          refreshDomainDetails={refreshDomainDetails}
        />
        {loadingOpportunities && <Loader />}
      </div>
    </div>
  );
};

export default BrowseBacklinksOpportunities;
