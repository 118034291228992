import styles from 'styles/components/giveBacklinkModal.module.scss';
import { Alert, Button, Divider, message, Modal } from 'antd';
import { useContext, useState } from 'react';
import { createRequest } from 'services/api/domains';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import OpportunityDetails from './opportunityDetails';
import { MAX_CONCURRENT_REQUESTS, REQUEST_TYPE } from 'utils/constants';
import DomainContext from 'services/domainContext';
import pluralize from 'pluralize';
import DomainMetrics from './domainMetrics';
import KarmaIcon from './karmaIcon';
import Instructions from './instructions';

const RequestBacklinkModal = ({
  isOpen = false,
  onClose,
  domain,
  opportunity,
  categories = [],
  type,
  refreshDomainDetails = () => {},
}) => {
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const { updateDomains } = useContext(DomainContext);
  const [instructions, setInstructions] = useState(null);

  if (!opportunity) return;

  const closeModal = () => {
    setConfirmed(false);
    setLoadingRequest(false);
    onClose();
  };

  const request = () => {
    setLoadingRequest(true);
    createRequest({
      domain: domain.name,
      opportunityId: opportunity._id,
      type: type,
      ...(instructions ? { instructions: instructions } : {}),
    })
      .then(() => {
        // message.success('Request sent');
        setConfirmed(true);
        setInstructions(null);
        updateDomains();
        refreshDomainDetails();
      })
      .catch((e) => {
        console.error(e);
        if (e.response.data.error === 'OPPORTUNITY_PAUSED') {
          message.error('This domain is currently paused');
        } else if (e.response.data.error === 'NOT_ENOUGH_POINTS') {
          message.error(
            'Not enough points to perform this action (you or the target website)'
          );
        } else if (e.response.data.error === 'BAD_INSTRUCTIONS') {
          message.error(
            'Issue with instructions. Sending contact information is strictly forbidden.'
          );
        } else {
          message.error('Issue when creating the request, please try again');
        }
      })
      .finally(() => {
        setLoadingRequest(false);
      });
  };

  const alertNoCategoryInCommon = (
    <Alert
      style={{ marginTop: 12 }}
      type="warning"
      showIcon={true}
      description="Be aware that this domain has no category in common with yours."
    />
  );

  const hasCategoryInCommon = opportunity.categories?.find((c) =>
    domain.categories?.includes(c)
  );

  const opportunityCategories = opportunity.categories?.map((r) =>
    categories.find((c) => c._id === r)
  );

  const onChangeInstructions = (e) => {
    setInstructions(e.target.value);
  };

  return (
    <Modal
      footer={null}
      open={isOpen}
      title={
        type === REQUEST_TYPE.GIVE
          ? 'Send a Backlink proposal'
          : 'Ask for a Backlink'
      }
      onCancel={closeModal}
    >
      {confirmed ? (
        <div className={styles.inner}>
          {type === REQUEST_TYPE.GIVE ? (
            <div className={styles.successInner}>
              <CheckCircleFilled className={styles.check} />
              <h1 style={{ marginBottom: 4 }}>Backlink proposal sent</h1>
              <div>The receipient has 7 days to answer</div>

              <div className={styles.nextSteps}>
                <div className={styles.nextStep}>
                  <div className={styles.nextStepIcon}>
                    <CheckCircleFilled
                      style={{ color: '#16b116' }}
                      className={styles.smallIcon}
                    />
                  </div>
                  <div>
                    If the website accepts, we’ll share their domain with you so
                    that you can ship the backlink. You’ll receive the points
                    once the backlink is Live.
                  </div>
                </div>
                <div className={styles.nextStep}>
                  <div className={styles.nextStepIcon}>
                    <CloseCircleFilled
                      style={{ color: 'red' }}
                      className={styles.smallIcon}
                    />
                  </div>
                  <div>
                    If the website refuses, nothing happens, you don’t need to
                    do anything.
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.successInner}>
              <CheckCircleFilled className={styles.check} />
              <h1 style={{ marginBottom: 4 }}>Backlink request sent</h1>
              <div>The receipient has 7 days to answer</div>

              <div className={styles.nextSteps}>
                <div className={styles.nextStep}>
                  <div className={styles.nextStepIcon}>
                    <CheckCircleFilled
                      style={{ color: '#16b116' }}
                      className={styles.smallIcon}
                    />
                  </div>
                  <div>
                    If the website accepts, they’ll have 7 days to can ship the
                    backlink
                  </div>
                </div>
                <div className={styles.nextStep}>
                  <div className={styles.nextStepIcon}>
                    <CloseCircleFilled
                      style={{ color: 'red' }}
                      className={styles.smallIcon}
                    />
                  </div>
                  <div>
                    If the website refuses, you’ll be refunded of the points.
                  </div>
                </div>
              </div>
            </div>
          )}
          <br />
          <div className={styles.ctaWrapper}>
            <Button type="primary" block onClick={closeModal}>
              Close
            </Button>
          </div>
        </div>
      ) : type === REQUEST_TYPE.GIVE ? (
        <div className={styles.inner}>
          Confirm that you want to send a backlink proposal to this website.
          <Divider />
          <DomainMetrics
            categories={opportunityCategories}
            domain={opportunity}
            canSeeYCCompanies={domain.isYC}
          />
          <Divider />
          <Alert
            type="info"
            showIcon
            description="We'll share your domain details with this website. They'll have 7 days to answer. If they accept a backlink from you, you’ll have 7 days to ship it."
          />
          {!hasCategoryInCommon && alertNoCategoryInCommon}
          <div className={styles.ctaWrapper}>
            <Button onClick={closeModal} type="link">
              Cancel
            </Button>
            <div className={styles.ctaRight}>
              <Button
                disabled={
                  domain.pendingGiveRequestsCount >= MAX_CONCURRENT_REQUESTS
                }
                onClick={request}
                type="primary"
                loading={loadingRequest}
              >
                Give Backlink<b>{` +${domain.metrics.authority.dr} `}</b>
                <KarmaIcon
                  fade={
                    domain.pendingGiveRequestsCount >= MAX_CONCURRENT_REQUESTS
                  }
                />
              </Button>
            </div>
          </div>
          <div
            style={{ color: 'grey', textAlign: 'right', marginTop: 4 }}
          >{`You have ${
            MAX_CONCURRENT_REQUESTS - domain.pendingGiveRequestsCount
          } concurrent ${pluralize(
            'request',
            MAX_CONCURRENT_REQUESTS - domain.pendingGiveRequestsCount
          )} remaining`}</div>
        </div>
      ) : (
        <div className={styles.inner}>
          Confirm that you want to ask for a backlink from this website.
          <Divider />
          <OpportunityDetails
            categories={categories}
            opportunity={opportunity}
          />
          <br />
          <Instructions onChangeInstructions={onChangeInstructions} />
          {!hasCategoryInCommon && alertNoCategoryInCommon}
          <div className={styles.ctaWrapper}>
            <Button onClick={closeModal} type="link">
              Cancel
            </Button>
            <div className={styles.ctaRight}>
              <Button onClick={request} type="primary" loading={loadingRequest}>
                Ask Backlink<b>{` -${opportunity.metrics.authority.dr} `}</b>
                <KarmaIcon />
              </Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default RequestBacklinkModal;
