/* eslint-disable react/style-prop-object */
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider, theme } from 'antd';
import CrispChat from 'components/crisp';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#08a87f',
          borderRadius: 10,
          // fontFamily: "'silka', sans-serif",
          boxShadow: 'none',
          colorLink: '#04cb99',
          controlHeight: 40,
          fontSize: 14,
          algorithm: theme.darkAlgorithm,
          colorTextBase: '#dcdcdc',
          colorTextPlaceholder: '#aeaeae',
          components: {
            Button: {
              colorPrimary: '#04cb99',
              algorithm: true, // Enable algorithm
            },
          },
        },
      }}
    >
      <App />
      <CrispChat />
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
