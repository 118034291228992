/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import {
  API_DEV_URL,
  API_URL,
  BACKLINK_REQUEST_STATUS,
  MAX_DAYS_TO_ANSWER,
} from './constants';
// import { Typography } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Tooltip } from 'antd';

export const getSessionId = () => localStorage.getItem('sessionId');

export const isConnected = () => {
  const item = getSessionId();
  return item && item != null && item !== 'undefined';
};

export const getCountLabel = (labelValue) =>
  Math.abs(Number(labelValue)) >= 1.0e9
    ? `${(Math.abs(Number(labelValue)) / 1.0e9).toFixed(1)}B`
    : Math.abs(Number(labelValue)) >= 1.0e6
    ? `${(Math.abs(Number(labelValue)) / 1.0e6).toFixed(1)}M`
    : Math.abs(Number(labelValue)) >= 1.0e3
    ? `${(Math.abs(Number(labelValue)) / 1.0e3).toFixed(1)}K`
    : Number.isInteger(labelValue)
    ? labelValue
    : Math.abs(Number(labelValue)).toFixed(1);

export const getApiURL = () => {
  const host = window.location.host.split('.')[0];
  if (host === 'app') {
    return API_URL;
  } else return API_DEV_URL;
};

export const isNumber = (nb) => typeof nb === 'number';

export const numberWithComma = (value) => value.toLocaleString('en-US');

export const isLocalhost = () =>
  window && window.location && window.location.hostname === 'localhost';

export const hostnameToURL = (hostname) => {
  // the inital value of the URL object can be anything
  const url = new URL('https://example.com');
  url.hostname = hostname;
  return url.href;
};

export const getStatusDetails = (domain, element) => {
  switch (element.status) {
    case 'PENDING':
      if (element.initiatedBy === domain._id) {
        return `The other domain has ${MAX_DAYS_TO_ANSWER} days to answer (${
          MAX_DAYS_TO_ANSWER -
          Math.abs(
            moment(element.acceptedAt || element.startedAt).diff(
              moment(new Date()),
              'days'
            )
          )
        } left)`;
      } else {
        return 'Answer as soon as you can';
      }
    case 'DONE':
      return (
        <div>
          <div>{`Submitted on ${moment(element.doneAt).format(
            'Do MMM YYYY'
          )}`}</div>
          {element.url && (
            <div>
              <br />
              <a href={element.url} target="_blank" rel="noreferrer">
                <LinkOutlined /> link
              </a>
              &nbsp;(Anchor text:&nbsp;<b>{element.anchorText}</b>)
            </div>
          )}
        </div>
      );
    case 'ACCEPTED':
      if (element.to === domain._id) {
        return (
          <div>
            <div>Waiting for the other domain to ship the backlink</div>
            <Tooltip
              title={
                element.instructions && element.instructions.length > 0
                  ? element.instructions
                  : null
              }
            >
              {element.instructions && element.instructions.length > 0 ? (
                <a>Instructions</a>
              ) : (
                <a>No instructions were left</a>
              )}
            </Tooltip>
          </div>
        );
      } else {
        return (
          <div>
            <div>Ship the backlink as soon as you can</div>
            <Tooltip
              title={
                element.instructions && element.instructions.length > 0
                  ? element.instructions
                  : null
              }
            >
              {element.instructions && element.instructions.length > 0 ? (
                <a>Instructions</a>
              ) : (
                <a>No instructions were left</a>
              )}
            </Tooltip>
          </div>
        );
      }

    default:
      return '';
  }
};
export const getLinkStatusTag = (opportunity) => {
  switch (opportunity.linkStatus) {
    default:
    case BACKLINK_REQUEST_STATUS.LIVE:
      return (
        <span
          style={{
            color: '#08ca11',
            fontWeight: 'bold',
          }}
        >
          🟢 LIVE
        </span>
      );
    case BACKLINK_REQUEST_STATUS.LOST:
      return (
        <div
          style={{
            color: '#ea0a0a',
            fontWeight: 'bold',
          }}
        >
          🔴 LOST
        </div>
      );
  }
};
export const getStatusTag = (element) => {
  switch (element) {
    default:
    case BACKLINK_REQUEST_STATUS.PENDING:
      return (
        <div
          style={{
            color: '#a064ff',
            fontWeight: 'bold',
          }}
        >
          {element}
        </div>
      );
    case BACKLINK_REQUEST_STATUS.ACCEPTED:
      return (
        <div
          style={{
            color: '#5d7bea',
            fontWeight: 'bold',
          }}
        >
          {element}
        </div>
      );
    case BACKLINK_REQUEST_STATUS.LIVE:
      return (
        <div
          style={{
            color: '#08ca11',
            fontWeight: 'bold',
          }}
        >
          {element}
        </div>
      );
    case BACKLINK_REQUEST_STATUS.WARNING:
      return (
        <div
          style={{
            color: '#e98b0c',
            fontWeight: 'bold',
          }}
        >
          {element}
        </div>
      );
    case BACKLINK_REQUEST_STATUS.LOST:
      return (
        <div
          style={{
            color: '#ea0a0a',
            fontWeight: 'bold',
          }}
        >
          {element}
        </div>
      );
    case BACKLINK_REQUEST_STATUS.EXPIRED:
      return (
        <div
          style={{
            color: '#d93a3a',
            fontWeight: 'bold',
          }}
        >
          EXPIRED
        </div>
      );
  }
};

export const flattenCategories = (subcateg) => {
  if (!subcateg || subcateg.length === 0) return [];
  return subcateg.map((c) => [c, ...flattenCategories(c.subCategories).flat()]);
};

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export const setSelectables = (categories) => {
  if (!categories || categories.length === 0) {
    return [];
  }
  return categories.reduce(
    (acc, element) => [
      ...acc,
      {
        ...element,
        subCategories: setSelectables(element.subCategories),
        isLeaf: element?.subCategories.length === 0,
        selectable:
          !element.subCategories || element.subCategories.length === 0,
      },
    ],
    []
  );
};
