import { LinkOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import Typography from 'antd/es/typography/Typography';
import DomainSettingsForm from 'components/domainSettingsForm';
import Loader from 'components/loader';
import isValidDomain from 'is-valid-domain';
import { useContext, useState } from 'react';
import { addDomain, getDefaultSettings } from 'services/api/domains';
import DomainContext from 'services/domainContext';
import ProfileContext from 'services/profileContext';
import styles from 'styles/components/onboarding.module.scss';
import { EMAIL_DOMAIN_STATUS, LOCAL_STORAGE } from 'utils/constants';

const EnterDomain = ({ nextStep }) => {
  const { profile } = useContext(ProfileContext);
  const [loading, setLoading] = useState(false);
  const [loadingSettings, setLoadingSettings] = useState(false);
  const [domainData, setDomainData] = useState(null);
  const [domainName, setDomainName] = useState(null);
  const [aiMentions, setAiMentions] = useState(true);
  const { domains, updateDomains } = useContext(DomainContext);

  const onFinish = (values) => {
    setLoading(true);
    let domainName = values.domain;
    if (domainName.split('.').length < 2) {
      //no . in domain name
      message.error('Error in your domain name, please verify.');
      setLoading(false);
      return;
    }
    if (domainName.substring(0, 12) === 'https://www.') {
      domainName = domainName.substring(12);
    } else if (domainName.substring(0, 4) === 'www.') {
      domainName = domainName.substring(4);
    } else {
      try {
        domainName = new URL(values.domain).hostname;
      } catch (r) {}
    }
    if (
      domainName.substring(domainName.length - 1, domainName.length) === '/'
    ) {
      domainName = domainName.substring(0, domainName.length - 1);
    }
    //remove spaces
    domainName = domainName.replace(/\s+/g, '');
    if (!isValidDomain(domainName, { subdomain: false })) {
      message.error(
        'Error in your domain name, make sure to remove the subdomain'
      );
      setLoading(false);
      return;
    }

    setDomainName(domainName);
    getDefaultSettings({ name: domainName })
      .then(({ data }) => {
        setDomainData(data);
        localStorage.setItem(
          LOCAL_STORAGE.CURRENT_DOMAIN_VALIDATION,
          domainName
        );
      })
      .catch((e) => {
        console.error(e);
        if (e.response.data.status === 400) {
          message.error('Invalid domain name.');
        } else if (
          e.response.data.error === 'REQUEST_TO_WEBSITE_FAILED' ||
          e.response.data.error === 'PREPARE_OPENAI_REQUEST' ||
          e.response.data.error === 'REQUEST_TO_OPENAI_FAILED' ||
          e.response.data.error === 'ERROR_PARSING_OPENAI_RESPONSE'
        ) {
          // message.error('Error while retrieving domain info, please try again');
          localStorage.setItem(
            LOCAL_STORAGE.CURRENT_DOMAIN_VALIDATION,
            domainName
          );
          setDomainData({});
          setAiMentions(false);
        } else {
          message.error('Error while retrieving domain info, please try again');
        }
        //
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishSettings = (values) => {
    setLoadingSettings(true);
    if (domains.find((d) => d.name === domainName)) {
      // this domain is already added, nextstep
      nextStep();
      return;
    }
    if (values?.categories?.length > 10) {
      setLoadingSettings(false);
      message.error(
        'Too many categories selected. Please remove some of them.'
      );
      return;
    }
    addDomain({
      name: domainName,
      language: values.language,
      categories: Array.isArray(values.categories)
        ? values.categories
        : [values.categories],
      description: values.description,
      frequency: values.frequency,
    })
      .then(({ data }) => {
        updateDomains().then(() => {
          message.success(
            <div>
              <b>{domainName}</b>&nbsp;has been added
            </div>
          );
          setTimeout(() => {
            nextStep();
          });
        });
      })
      .catch((e) => {
        console.error(e);
        if (e.response.data.error === 'IS_NOT_LEAF') {
          //IS_NOT_LEAF
          message.error(
            'You cannot select a parent category, please choose a more precise one.'
          );
        } else {
          message.error('Error while adding the domain, please try again');
        }
      })
      .finally(() => {
        setLoadingSettings(false);
      });
  };

  const alreadyHasOneDomainApproved =
    domains.filter(
      (e) => e.status !== 'TO_REVIEW' && e.status !== 'TO_RE_REVIEW'
    ).length > 0;

  return (
    <div>
      <h1 className={styles.title}>Add a Domain for link exchanges</h1>
      <div className={styles.criteriaWrapper}>
        KarmaLinks only accepts B2B SaaS, <br />
        so make sure <Typography.Text mark>it's a B2B Software</Typography.Text>
      </div>
      {profile ? (
        <div className={styles.formWrapper}>
          <Form
            layout="inline"
            style={{ justifyContent: 'center' }}
            onFinish={onFinish}
            initialValues={
              profile?.emailDomainStatus === EMAIL_DOMAIN_STATUS.COMPANY &&
              !alreadyHasOneDomainApproved
                ? { domain: profile.emailDomain }
                : {}
            }
          >
            <Form.Item
              label={'Enter your B2B SaaS domain name'}
              name="domain"
              placeholder="Enter the Domain name"
              layout="vertical"
              rules={[
                {
                  required: true,
                  message: 'Please input the domain name',
                },
              ]}
            >
              <Input
                size={'large'}
                prefix={<LinkOutlined />}
                placeholder="Ex: karmalinks.io"
              />
            </Form.Item>
            <Form.Item>
              <Button
                size={'large'}
                type="primary"
                loading={loading}
                htmlType={'submit'}
                style={{
                  marginTop: 28,
                }}
              >
                Use this domain
              </Button>
            </Form.Item>
          </Form>
          <br />
          <br />
          {loading && (
            <div
              style={{
                paddingTop: 24,
                paddingBottom: 24,
                textAlign: 'center',
                margin: 'auto',
              }}
            >
              <Loader tip="Analyzing domain..." />
            </div>
          )}
        </div>
      ) : (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
      {domainData && !loading && (
        <div className={styles.settingsWrapper}>
          <h1>{`Settings for ${domainName}`}</h1>
          <p className={styles.subtitle}>
            We gathered the main information about your domain
          </p>
          <div className={styles.settingsFormWrapper}>
            <DomainSettingsForm
              loadingSettings={loadingSettings}
              domainData={domainData}
              onFinishSettings={onFinishSettings}
              aiMentions={aiMentions}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EnterDomain;
