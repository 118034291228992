import { Tooltip } from 'antd';
import styles from 'styles/components/metricsVignette.module.scss';

const MetricsVignette = ({
  tooltipContent = null,
  value,
  label,
  large = false,
}) => (
  <Tooltip title={tooltipContent}>
    <div
      className={`${styles.metricsWrapper}${large ? ` ${styles.large}` : ''}`}
    >
      <div className={styles.metricsInner}>
        <div className={styles.metricsValue}>{value}</div>
        <div className={styles.metricsLabel}>{label}</div>
      </div>
    </div>
  </Tooltip>
);

export default MetricsVignette;
