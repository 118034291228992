import styles from 'styles/components/impersonateWarningBanner.module.scss';

const ImpersonateWarningBanner = ({ profile }) => (
  <div className={styles.wrapper}>
    <div className={styles.inner}>
      You are impersonating the account of <b>{profile.email}</b>
    </div>
  </div>
);

export default ImpersonateWarningBanner;
