import styles from "styles/components/pageWrapper.module.scss";

const PageWrapper = ({ children, width = 1140, style = {}, className }) => (
  <div
    className={`${styles.wrapper}${className ? ` ${className}` : ""}`}
    style={{ width: `${width}px`, ...style }}
  >
    <div className={styles.inner}>{children}</div>
  </div>
);

export default PageWrapper;
