import { FormOutlined } from '@ant-design/icons';
import { useState } from 'react';
import styles from 'styles/components/instructions.module.scss';
const { Input, Button } = require('antd');

const Instructions = ({ onChangeInstructions }) => {
  const [showInstructions, setShowInstructions] = useState(false);

  return showInstructions ? (
    <div className={styles.instructionsWrapper}>
      <div className={styles.instructionsTitle}>
        <b>Instructions (optionnal)</b>
        <br />
        <span style={{ color: '#bdbdbd' }}>
          Specify URL or anchor text for example.
        </span>
      </div>
      <Input.TextArea
        onChange={onChangeInstructions}
        placeholder="Enter instructions for your backlink"
      />
    </div>
  ) : (
    <div>
      <Button
        type="link"
        onClick={() => {
          setShowInstructions(true);
        }}
        icon={<FormOutlined />}
        style={{ marginLeft: 0, paddingLeft: 0 }}
      >
        Add instructions for the backlink
      </Button>
    </div>
  );
};

export default Instructions;
