/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';

const Auth = ({ getProfileInfo }) => {
  const navigate = useNavigate();
  const { sessionId } = useParams();
  useEffect(() => {
    localStorage.setItem('sessionId', sessionId);
    getProfileInfo().finally(() => {
      navigate('/dashboard');
    });
  }, []);
  return (
    <div>
      Loading...
    </div>
  );
};

export default Auth;
